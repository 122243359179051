<script setup lang="ts">

import {storeToRefs} from "pinia";
import {PassengerTypes} from "~/utils/flight/passenger/PassengerTypes";
import type {PassengerRule} from "~/types/flight/passenger/PassengerRule";
import type {FlightPassengerItem} from "~/types/flight/passenger/FlightPassengerItem";
import {PassengerTypeCount} from "~/utils/flight/passenger/PassengerTypeCount";
import {PassengerCountCompare} from "~/utils/flight/passenger/PassengerCountCompare";
import {minusIcon, plusIcon} from "~/utils/icon-utils";

const props = defineProps({
    passengers: {
        type: Array as () => FlightPassengerItem[],
        required: true
    },
    open: {
        type: Boolean,
        default: false
    },
    showInput: {
        type: Boolean,
        default: true
    }
})
const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);

const passengerCounts = computed(() => PassengerTypeCount(props.passengers));
const comparison = computed(() =>
    PassengerCountCompare(
        passengerCounts.value.adlCount,
        passengerCounts.value.chdCount,
        passengerCounts.value.infCount
    )
);

const adlCount = computed(() => passengerCounts.value.adlCount);
const chdCount = computed(() => passengerCounts.value.chdCount);
const infCount = computed(() => passengerCounts.value.infCount);
const totalCount = computed(() => passengerCounts.value.totalCount);


const isChildesBiggerThanOrEqualAdl = computed(() => comparison.value.isChildesBiggerThanOrEqualAdl);
const isInfantBiggerThanOrEqualAdl = computed(() => comparison.value.isInfantBiggerThanOrEqualAdl);

const emits = defineEmits(["close", "handlePassenger"])

const passengerTypes = PassengerTypes()

const showPassengersOptions = ref<Boolean>(props.open)

const handlePassenger = (passenger: PassengerRule, action: 'add' | 'remove') => emits('handlePassenger', {
    passenger,
    action
})
const submit = () => {
    showPassengersOptions.value = false
    emits('close', showPassengersOptions.value)
}

watch(() => props.open, () => {
    showPassengersOptions.value = props.open
})

</script>

<template>
    <div v-if="showPassengersOptions"
         class="md:min-w-[400px] w-full lg:absolute z-40 top-[103%] left-0 whitespace-nowrap text-ellipsis">
        <div class="rounded-[5px] lg:shadow-sm lg:border lg:border-gray-200 bg-white">
            <div class="max-h-screen lg:max-h-[410px] overflow-auto lg:py-4 lg:px-5 relative z-40">

                <div
                    v-for="(passengerType, index) in passengerTypes"
                    :key="index"
                    class="flex w-full items-center justify-between py-4"
                >
                    <div class="flex flex-col text-sm min-w-max gap-1">
                        <span v-text="passengerType.fa_name"></span>
                        <span class="text-xs text-gray-400"
                              v-text="`(${passengerType.rule})`"></span>
                    </div>
                    <div class="flex items-center">
                        <u-button
                            @click="handlePassenger(passengerType, 'add')"
                            :icon="plusIcon()"
                            variant="outline"
                            square
                            size="md"
                            :ui="{base: 'rounded-full'}"
                            :disabled="totalCount >= 9 || ((passengerType.type === 'inf' || passengerType.type === 'chd') && isChildesBiggerThanOrEqualAdl) || (passengerType.type === 'inf' && isInfantBiggerThanOrEqualAdl)"
                        />

                        <span class="min-w-[40px] text-center px-2"
                              v-text="passengerType.type === 'adl' ? adlCount : passengerType.type === 'chd' ?  chdCount : infCount"></span>
                        <u-button
                            @click="handlePassenger(passengerType, 'remove')"
                            :icon="minusIcon()"
                            variant="outline"
                            square
                            size="md"
                            :ui="{base: 'rounded-full'}"
                            :disabled="passengerType.type === 'adl' && ((adlCount - 1 <= (chdCount + infCount) / 3) || adlCount === 1) || (passengerType.type === 'chd' && !chdCount) || (passengerType.type === 'inf' && !infCount)"
                        />
                    </div>
                </div>
            </div>

            <div
                class="py-4 px-5 border border-gray-200 rounded-t-[5px]"
                :class="!isDesktop ? 'fixed bottom-0 inset-x-0' : ''"
            >
                <u-button
                    @click="submit"
                    :label="isDesktop ? `تایید - ${ totalCount } مسافر` : `جستجو - ${totalCount} مسافر`"
                    type="button"
                    size="xl"
                    block
                />
            </div>
        </div>
    </div>
</template>
