import type {PassengerRule} from "~/types/flight/passenger/PassengerRule";

export const PassengerTypes = (): PassengerRule[] => {
    return [
        {
            type: 'adl',
            name: 'adult',
            fa_name: 'بزرگسال',
            rule: '12 سال به بالا'
        },
        {
            type: 'chd',
            name: 'child',
            fa_name: 'کودک',
            rule: '2 تا 12 سال'
        },
        {
            type: 'inf',
            name: 'infant',
            fa_name: 'نوزاد',
            rule: '10 روز تا 2 سال'
        }
    ]
}